.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Loader {
  display: block;
  position: fixed;
  margin: 20% 50% 20% 50%;
  z-index: 100;
}

.link:hover {
  text-decoration: none;
  color: white;
}

.link {
  color: white;
  text-decoration: none;
}

.companyname {
  font-size: 22px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.modal-form {
  width: 100%;
}

.sidenav {
  height: 100vh;
  /* Full-height: remove this if you want "auto" height */
  width: 250px;
  /* Set the width of the sidebar */
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  background-color: #055646;
  /* Black */
  color: #f7f4f4f5;
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    margin-left: 20%;
  }

  .Login form {
    align-content: center;
    margin: 0 auto;
    max-width: 320px;
  }
}

.courseEdit {
  margin-top: 200%;
  margin-left: 25%;
}

.back-button-div {
  margin: auto 0 0;
}

.back-button {
  background-color: #f1f1f1;
}

.settings-button {
  /* margin-top: 150px; */
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  margin-left: 80%;
  margin-right: 10%;
  border-radius: 100%;
}

/* Style page content */
.main {
  /* margin-right: 30%; */
  padding: 0px 10px;
}

.border-line {
  display: block;
  margin-top: 110px;
  border-width: 2px;
}

.main-page {
  margin-left: 300px;
  margin-top: 15px;
}

.quiz-page {
  margin-left: 300px;
  margin-top: 15px;
  margin-right: 30px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

@media (min-width: 576px) {

  .modal-dialog.modal-sm {
    max-width: 400px !important;
  }

  .modal-dialog.modal-md {
    max-width: 720px !important;
  }

  .modal-dialog {
    max-width: 80% !important;
  }
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: '\00a0\00a0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '\039F';
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  background-color: #5cb85c;
  color: white;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.certi-container {
  /* margin-left:75px; */
  min-height: 100vh;
  /* min-width: 1370px; */
  padding-right: 30px;
  background: #055646;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  overflow: hidden;
}

.msb-btn-container {
  display: inline;
  position: absolute;
  margin-top: -5px;
}

.msb-btn {
  background: #dc3545;
  border-radius: 50%;
  padding: 0;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: transparent;
  float: top;
}

#mainSideBar {
  z-index: 1;
  -webkit-transition: 2s ease;
  /* Safari */
  transition: 2s ease;
}

.msb-btn:hover {
  cursor: pointer;
}

.msb-btn:focus {
  outline: none;
  box-shadow: none;
}

.certi-header {
  width: 102%;
  background: #055646;
  height: 55px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  box-shadow: 0 0 14.6px 1.4px rgba(0, 0, 0, 0.2);
  /* padding-top:20px; */
  /* padding-left:54px; */
}

.certiheadertext {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

body {
  overflow: auto;
}

.certi-sidepanel {
  background: transparent;
  height: calc(100vh - 70px);
  /* border-right:0.5px solid #000; */
  /* border-top:0.5px solid #000; */
}

.table-container {
  border-top: 0.5px solid #000;
  padding: 0 !important;
  background: #fff;
}

.state-btn {
  cursor: pointer;
  width: 100%;
  margin-left: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  text-transform: capitalize;
  border: 0.5px solid #fff;
  border-radius: 4px;
  color: #fff;
  text-align: left;
  background: transparent;
}

.state-btn:hover {
  background: rgba(0, 0, 0, 0.2);
}

.control-btn {
  cursor: pointer;
  width: 100%;
  margin-left: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  text-transform: capitalize;
  border: 0.5px solid #fff;
  border-radius: 4px;
  color: rgb(7, 125, 189);
  text-align: left;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.control-btn:hover {
  background: #ebe9e9;
}

.state-btn-container {
  margin-top: 15px;
}

.active-btn {
  color: #fff;
  background: #dc3545;
  background: #fff;
  color: rgb(7, 125, 189);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.active-btn:hover {
  background: rgba(255, 255, 255, 1);
}

.tracking-inp {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  border-width: 1px;
  border-radius: 2px;
  background: transparent;
}

.tracking-inp:focus {
  background: #fff;
}

/* th{
    width:0px!important;
  } */
.text-center {
  text-align: center;
}

.comment-inp {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  border-width: 1px;
  border-radius: 2px;
  background: transparent;
  align-self: center !important;
}

.comment-inp:focus {
  background: #fff;
}

.padding0 {
  padding: 0;
}

.status-container {}

.MuiTableRow-hover-106 {
  background: transparent !important;
}

.status-select {
  width: 90% !important;
  height: 100% !important;
  border-radius: 0 !important;
  background: transparent !important;
  margin: 0 !important;
  margin-left: 10% !important;
}

.status-select:active {}

.status-select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.row-checkBox-container {
  text-align: center;
}

/* .container-td{
    padding-bottom:0!important;
    vertical-align: center;
    text-align: center;
    padding-top:25px!important;
  }

  .container-td-2{
    
    vertical-align: center;
    padding-top:18px!important;
    text-align: center;
  } */

.table td {
  vertical-align: middle !important;
}

.cf-req-group {
  margin: 0 !important;
}

.container-td {
  text-align: center;
}

.row-selected {
  background: hsla(339, 100%, 48%, 0.1);
}

.table-container {
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.cf-table-header {
  color: rgb(7, 125, 189);
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.margin-bottom-1rem {
  margin-bottom: 1rem !important;
}

.dropdown-btn {
  padding: 0;
}

/* .MuiInputBase-root-66 {
  width:50%!important;
} */
.MuiInputBase-input-76 {}

.DateRangePicker {
  z-index: 200;
  border: 1px solid #000;
  background: #fff;
  position: absolute !important;
  margin-left: -40px;
}

.multiDatePicker-container {
  width: 100px;
}

.multiDatePicker-btn {
  padding: 0;
  padding-left: 2px;
  background: transparent;
  color: rgb(7, 125, 189);
}

.multiDatePicker-btn:focus,
.multiDatePicker-btn:active {
  outline: none;
}

.DateRangePicker__CalendarSelection {
  /* color: rgb(7, 125, 189)!important; */
  /* background-color:#f50057!important;
  border-color:#f50057!important; */
  background-color: rgb(7, 125, 189) !important;
  border-color: rgb(7, 125, 189) !important;
}

.dropdown-toggle {
  background: transparent !important;
  color: rgb(7, 125, 189) !important;
  border: 0 !important;
  padding: 0 !important;
  margin-bottom: -3px;
}

.dropdown-toggle:focus,
.dropdown-toggle:active {
  outine: none !important;
  box-shadow: none !important;
}

.courses-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: max-content;
  overflow-y: scroll;
  max-height: 1000px;
}

/* .MuiIconButton-root-11{
  padding:0!important;
  padding-top:5px!important ;
  


} */
.courseName {
  /* vertical-align:bottom; */
  line-height: 25px;
}

.cf-cd-li {
  margin-left: -8px;
}

.course-container {
  padding-left: 5px;
  padding-right: 8px;
}

.course-item {}

.checkbox-container {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  height: 20px;
  width: 40px;
  margin-top: -5px;
}

.person-name {
  float: inherit;
  display: block;
  vertical-align: bottom;
  padding-left: 3px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
  cursor: pointer;
}

.person-name:hover {
  text-decoration: underline;
}

.cf-coursename {
  white-space: nowrap;
  overflow: hidden;
  max-width: 99px;
  display: block;
}

.cb-container {
  display: inline-block;
  float: left;
  margin-left: -5px;
}

.printCerti {
  display: inline-block;
  float: right;
}

.cf-path {
  display: inline-block;
  font-size: 16px;
  color: rgb(5, 107, 161);
  font-weight: bold;
  margin-top: 11px;
}

.user-1 {
  margin-top: 5px;
  font-size: 16px;
}

.cf-inp {
  width: 740px !important;

  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  border: 0.5px solid rgb(223, 223, 223);
}

.cf-inp-container {
  margin: 4px;
  margin-left: 10px;
}

.cf-labels {
  width: 150px;
}

.cf-save-btn {
  margin-left: 10px;
  width: 390px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
}

.cf-save-btn:hover {
  cursor: pointer;
  background: #a1a1a1;
}

.user-form-1 {
  font-size: 14px;
}

.cf-select {
  width: 240px !important;
  display: inline-block !important;
}

.cf-select:active,
.cf-select:focus {
  outline: 0 !important;
}

.cf-select-label {
  display: inline-block;
  width: 150px;
}

.cf-inp-label {
  border: 0 !important;
}

.cf-inp:focus {
  outline: 0 !important;
  box-shadow: 0 !important;
}

.user-2 {
  /* border-top:1px solid #000; */
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
}

.cf-edit-btn {
  float: right;
  border-radius: 5px;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.cf-show-border {
  border: 0.5px solid rgb(223, 223, 223);
}

.cf-hide-border {
  border: 0;
}

.cf-save-btn-2 {
  width: 49.5%;
  margin-right: 0.5%;
  border-radius: 5px;
}

.cf-cancel-btn {
  width: 49.5%;
  margin-left: 0.5%;
  border-radius: 5px;
}

.cf-save-cancel {
  margin-left: 10px;
  width: 390px;
  margin-top: 5px;
}

.certi-heading {
  float: left;
  cursor: pointer;
  font-family: 'Source Sans Pro';
  color: #fff;
}

.cf-button-panel {
  font-size: 12px;
  float: right;
  margin-right: 20px;
  display: none;
}

.cf-buttons {
  display: inline;
}

#statusDropdown {
  display: inline !important;
}

/* .MuiIconButton-root-25{
  padding:0!important;
} */
.RadioButtonsGroup-formControl-2 {
  margin: 0 !important;
  margin-left: 24px !important;
}

#cf-status-btn {
  vertical-align: top;
  padding: 0 !important;
  font-size: 22px;
  height: 25px !important;
  width: 25px !important;
  background: transparent !important;
  border: none !important;
}

#cf-status-btn:focus,
#cf-status-btn:active {
  box-shadow: none !important;
  outline: none !important;
}

.cf-status-icon {
  color: #000;
}

.cf-date {
  width: 50px;
  padding-right: 40px;
}

.cf-dd-input {
  border: 1px solid #d2d2d2;
  margin: 5px;
  padding: 2px;
  border-radius: 3px;
}

.cf-change {
  font-size: 12px;
  margin: 0;
  padding: 5px;
}

#ddDropdown {
  display: inline !important;
}

#cf-dd-btn {
  padding: 0 !important;
  font-size: 22px;
  vertical-align: top;
  height: 25px !important;
  width: 25px !important;
  background: transparent !important;
  border: none !important;
  margin-left: 20px;
}

#cf-dd-btn:focus,
#cf-dd-btn:active {
  box-shadow: none !important;
  outline: none !important;
}

.cf-print-btn {
  padding: 0;
  font-size: 14px;
  float: right;
  display: block;
  background-color: #fff;
  color: rgb(7, 125, 189);
  vertical-align: bottom;
  margin-left: 20px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid rgb(7, 125, 189);
  font-weight: bold;
  /* background: transparent; */
}

.cf-print-btn:hover {
  background: #ebe9e9;
}

.cf-courier-partner-btn {
  padding: 0;
  font-size: 22px;
  vertical-align: bottom;

  padding-top: 5px;
  background: transparent;
  margin-left: 20px;
}

.item-selected {
  padding: 0;
  width: 100px;
  font-size: 14px;
  vertical-align: bottom;
  margin-left: 20px;
  padding-top: 5px;
  background: transparent;
  text-transform: lowercase;
}

.cf-refresh-btn {
  float: right;
  padding: 0;
  background: none;
  font-size: 16px;
}

.cf-logo {
  height: 25px;
  width: 25px;
  margin-top: -3px;
}

.cf-row1 {
  /* background: rgba(162, 169, 173,0.2); */
  background: #fff;
}

.cf-row0 {
  background: rgba(7, 125, 189, 0.2);
  /* color: rgb(7, 125, 189)!important; */
}

/* .MuiCheckbox-colorSecondary-91.MuiCheckbox-checked-87{
} */
.cf-show-more {
  margin: 0 auto;
  display: block;
  margin-bottom: 8px;
  border-radius: 3px;
  box-shadow: 0 0 5.6px 1.4px rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
  min-width: 25%;
  color: #fff;
  background: rgb(7, 125, 189);
  transition: 1s;
  cursor: pointer;
}

.cf-show-more:hover {
  background: rgb(5, 107, 161);
  color: #fff;
}

.cp-select {
  background: transparent !important;
  width: 80px !important;
}

.cp-select:focus {
  background: #fff !important;
}

.cf-save-cr-btn {
  background: rgb(7, 125, 189) !important;
  color: #fff;
  border-radius: 4px;
}

.cf-save-cr-btn:hover {
  background: rgb(5, 107, 161) !important;
  cursor: pointer;
}

.cf-loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 200;

  background: rgba(0, 0, 0, 0.5);
}

.cf-loader-container {
  width: 30px;
  height: 30px;
  margin: 0 auto !important;
  margin-top: 50vh !important;
}

.sweet-loading {
  width: 30px !important;
  height: 30px !important;
  margin: 0 auto !important;
  /* margin-top: 50vh!important; */
}

/* .MuiCheckbox-colorSecondary-103.MuiCheckbox-checked-99{
 color:rgb(5, 107, 161)!important; 
}
.MuiCheckbox-colorSecondary-737{
  padding:0!important;
}
.MuiCheckbox-colorSecondary-737.MuiCheckbox-checked-733{
  color:rgb(5, 107, 161)!important; 
}
.MuiCheckbox-colorSecondary-129.MuiCheckbox-checked-125{
  color:rgb(5, 107, 161)!important; 
}
.MuiCheckbox-colorSecondary-91.MuiCheckbox-checked-87{
  color:rgb(5, 107, 161)!important; 
}
.MuiButtonBase-root-69 {
  padding:0!important;
} */
.cf-req-count {
  color: #fff;
  font-size: 14px;
  float: right;
  text-transform: lowercase;
  /* font-family:'Source Sans Pro'; */
}

.courier-partner-modal {
  border-radius: 4px;
}

.cp-modal-btn {
  margin-top: 8px;
  background: rgb(7, 125, 189) !important;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.cp-modal-msg {
  margin: 3px;
  color: #999999;
}

.cp-modal-heading {
  color: rgb(7, 125, 189);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 16px;
}

.cp-modal-header {
  background: rgba(7, 125, 189, 0.2);
}

.cf-ud-1 {
  min-height: calc(100% - 50px) !important;
}

.cf-ud-2 {
  min-height: calc(100% - 50px) !important;
  /* background: blu */
}

.cf-ud-header {
  background: rgba(7, 125, 189, 0.2);
  height: 40px;
  box-shadow: 0 0 14.6px 1.4px rgba(0, 0, 0, 0.2);
  /* border-bottom:1px solid rgb(7, 125, 189); */
}

.cf-ud-row {
  margin: 0 !important;
  height: 100%;
  /* width:100%; */
}

.cf-right-container {
  background: rgba(7, 125, 189, 0.2);
  min-height: 94%;
  margin-top: 3%;
  padding: 0 !important;
  border-radius: 5px;
  box-shadow: 0 0 14.6px 1.4px rgba(0, 0, 0, 0.2);
}

.cf-left-container {
  background: rgba(7, 125, 189, 0.2);
  min-height: 94%;
  margin-top: 3%;
  border-radius: 5px;
  padding: 0 !important;
  box-shadow: 0 0 14.6px 1.4px rgba(0, 0, 0, 0.2);
}

.cf-ud-address {
  height: 120px;
  max-height: 190px;
  overflow-y: scroll;
  background: transparent;
  border: none;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.cf-detail-key {
  padding: 2px;
  padding-top: 10px;
  font-size: 16px;
  width: 100%;
  background: transparent;
}

.cf-detail-value {
  padding: 2px;
  padding-top: 10px;
  font-size: 16px;
  width: 100%;
  overflow: auto;
  /* color:rgb(7, 125, 189); */
}

.cf-ud-subheading {
  font-size: 16px;
  padding: 9px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  /* padding-left:1px; */
  background: rgb(7, 125, 189);
  margin: 0 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cf-data-row {
  margin: 0 !important;
}

.cf-a_date {
  float: inherit;
  display: block;
  padding-left: 3px;
  vertical-align: bottom;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}

.cf-d_date {
  float: inherit;
  display: block;
  vertical-align: bottom;
  padding-left: 3px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}

.main-page-btn {
  cursor: pointer;
}

.mt-5 {
  margin-top: 5%;
}

/* LEARNING MONITORING DASHBOARD */

.selected {
  background: #00925b;
}

.lmp-outercontanier {
  display: table;
  width: 100%;
  height: calc(100vh - 60px);
}

.lpheader {
  background-color: var(--green-color);
  height: 60px;
  /* border-bottom: 2px solid rgb(243, 235, 235); */
  /* box-shadow: 0 0 16px 1.4px #ffffff; */
  color: #ffffff;
  font-size: 22px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  padding: 0;
  display: flex;
}

.lpheadertext {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.lpsidebar {
  background-color: var(--green-color);
  color: #f7f4f4f5;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  gap: 1rem;
}

.lmp-content {
  display: table-cell;
  vertical-align: top;
  margin-bottom: 80px;
}

.showPopupSkill {
  width: 260px;
  margin-top: -70px;
  border: 16px solid;
  border-color: transparent;
  margin-left: 4px;
  position: absolute;
  z-index: 100;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

/* .showJobPopupSkill3 {
  width: 260px;
  margin-top: 50px;
  border: 16px solid;
  border-color: transparent;
  margin-left: 44px;
  position: absolute;
  z-index: 100;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize; 
} */

.showJobPopupSkill {
  width: 260px;
  margin-top: 50px;
  border: 16px solid;
  border-color: transparent;
  margin-left: 204px;
  position: absolute;
  z-index: 100;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.showJobLocationPopup {
  width: 260px;
  margin-top: 0px;
  border: 16px solid;
  border-color: transparent;
  margin-left: 240px;
  position: absolute;
  z-index: 100;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.showJobPopupSkill2 {
  width: 260px;
  margin-top: 20px;
  border: 16px solid;
  border-color: transparent;
  margin-left: 175px;
  position: absolute;
  z-index: 100;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.showJobPopupSkill3 {
  width: 260px;
  margin-top: 30px;
  border: 16px solid;
  border-color: transparent;
  margin-left: 64px;
  position: absolute;
  z-index: 999;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 400;
}

.darken-on-hover {
  position: relative;
}

.darken-on-hover:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.5s;
}

.lpsidebar-headers {
  background-color: transparent;
  border: 1px solid #ffffff;
  font-size: 16px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 8px;
  color: white;
  width: 140px;
  /* margin: 20px; */
  padding: 10px;
}

.lpsidebar-headers:hover {
  opacity: 0.7;
  /* font-size: 17px; */
}

.lmp-course-details {
  border: 2px bold #000;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 0 0 16px 1.4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  border-radius: 10px;
  margin: 20px;
}

.lmp-coursepagehead {
  background-color: var(--green-color);
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  padding: 20px;
}

.lmp-course-details-heading {
  padding: 30px;
}

.lmp-courseactivity-details {
  border: 2px bold #000;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 0 0 16px 1.4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  border-radius: 10px;
  width: 65%;
  margin: 12px;
}

.lmp-courseactivity-details-heading {
  padding: 10px;
}

.lmp-button {
  background-color: var(--green-color);
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px;
  color: #ffffff;
  margin-left: 20px;
  font-size: 16px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
  /* margin-bottom: 100px */
}

.lmp-button:hover {
  /* opacity: 0.9; */
  /* color : #2599a8 */
  font-size: 17px;
}

.lmp-back-button {
  background-color: var(--green-color);
  border-radius: 5px;
  width: 100px;
  margin-top: 20px;
  /* margin-left: 20px; */
  float: right;
  padding: 12px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

.lmp-back-button:hover {
  opacity: 0.9;
  /* color : #2599a8 */
  font-size: 15px;
}

.lmp-coursepage-label {
  margin: 15px;
  margin-top: 20px;
  font-size: 18px;
  padding: 8px;
  font-weight: bold;
  color: var(--green-color);
  font-family: 'Source Sans Pro', sans-serif;
  border-bottom: 2px solid var(--green-color);
  border-radius: 5px;
}

.lmp-course-row:hover {
  cursor: pointer;
}

.lmp-npscontainier {
  margin-left: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.lmpanel-trecords-label {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
}

.lmp-search-button {
  background-color: var(--green-color);
  border-radius: 5px;
  margin-top: 30px;
  width: 100px;
  padding: 12px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

.lmp-search-button:hover {
  opacity: 0.9;
  /* font-size: 15px; */
}

.lmp-norecord {
  border: 2px bold #000;
  text-align: center;
  color: red;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 0 0 16px 1.4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  margin: 70px;
  padding: 15px;
}

.lmp-tablerowhover {
  cursor: pointer;
}

.lmp-tablerowhover:hover {
  box-shadow: 0 0 16px 1.4px var(--green-color);
  /* border: 2px solid var(--green-color); */
}

.lmp-loader {
  display: block;
  position: fixed;
  margin: 20% 50% 20% 35%;
  z-index: 100;
}

.date-picker-lmp .DateRangePicker {
  z-index: 200;
  border: 1px solid #000;
  background: #fff;
}

/* Coding Assignments */

.coding-assgn-heading {
  color: white;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.coding-assgn-stepheading {
  font-family: 'Source Sans Pro', sans-serif;
  padding-top: 20px;
  padding-bottom: 5px;
  color: var(--green-color);
  border-bottom: 1px solid var(--green-color);
}

.coding-asgn-btn {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.coding-asgn-testcase-btn:hover {
  opacity: 0.7;
}

.ide-result-card {
  border-radius: 5px;
  padding: 3px;
  margin-top: 20px;
  /* box-shadow: 0 0 16px 1.4px rgba(0,0,0,.2); */
}

.ide-result-card-cmp-error {
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  box-shadow: 0 0 16px 1.4px rgba(0, 0, 0, 0.2);
}

.ace-monokai .ace_print-margin {
  width: 0px !important;
}

.ace_editor {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.editor-reset-code-button {
  border: 1px solid white !important;
  margin: 5px 0px 5px 20px;
  background-color: transparent !important;
}

.editor-reset-code-button:hover {
  opacity: 0.7;
  border: 1px solid white;
  margin: 5px 0px 5px 20px;
  background-color: transparent;
}

/*.modal-dialog .modal-title{
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #212A39;
}*/
#mainSideBar+.headFlex {
  padding: 0 5%;
  margin-left: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.assessmentSubmissions .MuiInputLabel-root,
.assessmentSubmissions .MuiInput-input,
.assessmentSubmissions .MuiSelect-icon,
.assessmentSubmissions .MuiInputLabel-root.Mui-focused {
  color: white !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

/* assignment deadline styling */
:root {
  --white-color: #ffffff;
  --blue-color: #255ca8;
  --green-color: #035642;
}

.MuiFormLabel-root.Mui-focused {
  color: black !important;
}

.as-dl-sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  background-color: #055646;
  color: var(--white-color);
  overflow-x: hidden;
  z-index: 1;
}

.as-fdbk-sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 271px;
  background-color: #055646;
  color: var(--white-color);
  overflow-x: hidden;
  z-index: 1;
}

.as-dl-back {
  width: 250px;
  font-size: 20px;
  height: 80px;
  text-align: left;
  padding: 30px;
  color: var(--white-color);
  line-height: 24px;
}

.as-dl-back a {
  color: var(--white-color);
  font-weight: 600;
  cursor: pointer;
}

.as-dl-back a:hover {
  color: var(--white-color);
}

.as-dl-back i {
  margin-right: 10px;
  cursor: pointer;
}

.as-dl-sidebar-lg {
  height: 80px;
}

.as-dl-logo {
  height: 32px;
  margin-top: 30px;
  width: auto;
  margin-left: 40px;
}

.as-dl-logo-job {
  height: 32px;
  margin-top: 30px;
  width: auto;
  margin-left: 20px;
  border-radius: 5px;
}

.as-dl-logo-job-2 {
  height: 32px;
  /* margin-top: 30px; */
  width: auto;
  /* margin-left: 20px; */
}

.as-dl-logo-hp {
  height: 32px;
  width: auto;
}

.as-dl-logo-cp {
  height: 32px;
  margin-top: 12px;
  width: 8.4vw;
  margin-left: 3vw;
  margin-right: 70px;
}

.as-dl-logo-lp {
  height: 32px;
  margin-top: 12px;
  width: 155px;
  margin-left: 40px;
  margin-right: 70px;
}

.as-dl-logo-up {
  height: 32px;
  margin-top: 32px;
  width: auto;
  margin-left: 40px;
  margin-right: 50px;
}

.as-dl-header {
  margin-left: 250px;
  height: 80px;
  width: 100% - 250px;
  background-color: #055646;
  color: var(--white-color);
  font-size: 24px;
  line-height: 80px;
  font-weight: 800;
  text-align: center;
}

.as-gd-header {
  margin-left: 250px;
  height: 60px;
  width: 100% - 250px;
  background-color: #055646;
  color: var(--white-color);
  font-size: 24px;
  line-height: 60px;
  font-weight: 800;
  text-align: center;
}

.as-dl-mdl {
  max-width: 500px;
  margin-left: 30%;
}

.as-dl-mdl-sc {
  max-width: 360px;
  margin-left: 30%;
}

.as-dl-ovrrd-sl {
  max-width: 500px;
}

.as-dl-success {
  height: 112px;
  width: 112px;
  margin-top: 20px;
}

.as-dl-success-txt {
  color: #212a39;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 30px 0;
}

.as-dl-success-btn {
  background-color: #255ca8 !important;
  border-radius: 5px;
  width: 172px;
  height: 48px;
  margin-top: 20px;
}

.as-dl-select {
  border: 1px solid var(--white-color) !important;
  border-radius: 4px !important;
  width: 200px;
  margin-left: 23px;
  min-height: 48px;
  background-color: var(--green-color) !important;
  color: var(--white-color) !important;
  margin-top: 50px;
  font-weight: 600;
  text-align: left !important;
  padding-left: 10px !important;
  font-size: 18px !important;
  opacity: 0.75;
}

.as-dl-select-mt {
  margin-top: 20px;
}

.as-dl-select div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px !important;
  display: inline-flex;
}

.as-dl-select:disabled {
  cursor: not-allowed;
  opacity: 0.3 !important;
}

.as-dl-select i {
  float: right;
  font-size: 25px;
}

.as-dl-menu {
  max-width: 200px;
  cursor: pointer;
  overflow: scroll;
  max-height: 400px;
}

.dropdown-item {
  white-space: normal !important;
  line-height: 22px;
}

.as-dl-main {
  margin-left: 250px;
  width: calc(100% - 250px);
  margin-top: 40px;
}

.as-dl-table {
  width: 90% !important;
  margin: 20px auto;
}

.horizontal-divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #979797;
}

.as-dl-search {
  height: 40px;
  width: 300px !important;
  display: inline-block !important;
}

.as-dl-override {
  background-color: var(--green-color) !important;
  float: right;
  font-weight: 600 !important;
  height: 40px;
  padding: 0 35px !important;
}

.as-dl-th {
  font-size: 16px;
  font-weight: 600;
}

.as-dl-pagination {
  display: block;
  margin: 0 auto;
}

.MuiAppBar-colorPrimary {
  background-color: #055646 !important;
}

.nav-tabs .nav-item {
  /* width: calc(50vw - 150px); */
  min-width: 200px;
  /* margin: auto; */
  flex-grow: 1;
  border: none !important;
  margin-bottom: 0 !important;
}

.nav-tabs {
  margin: 10px 0 0 10px;
  background: white;
  border: 2px solid #055646 !important;
}

.batchManagement .nav-tabs .nav-item:nth-child(2) {
  border-left: 2px solid white !important;
  border-right: 2px solid white !important;
}

.nav-tabs a.nav-link,
.nav-tabs a.nav-link:hover {
  background: unset;
  background-color: #055646 !important;
  color: white !important;
}

.nav-tabs a.nav-link:hover {
  background: unset;
  background-color: #055646;
  color: white;
}

.nav-tabs .nav-item.show a.nav-link {
  /* background-color: rgb(124 205 216 / 43%); */
  background: white;
  border-width: 2px;
  color: initial;
}

.nav-tabs a.nav-link.active {
  background: white !important;
  border-width: 2px !important;
  color: initial !important;
}

.nav-tabs .nav-link {
  border: none !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.AddDocument__extrafile {
  color: #0f5644;
  border: 1px solid #0f5644;
  padding: 0.2rem;
  border-radius: 2px;
  margin-right: 1rem;
}

.AddDocument__primary-file {
  color: white;
  background: #0f5644;
  padding: 0.2rem;
  border-radius: 2px;
  margin-right: 1rem;
}

.liveLectureToolTip [data-tooltip]:after {
  text-align: center !important;
  white-space: normal !important;
  width: 200px !important;
  max-width: initial !important;
}

.LogsModal .MuiAccordionDetails-root {
  display: block !important;
}

.LogsModal .modal-content {
  min-height: 94vh;
  max-height: 94vh;
  overflow: scroll;
}

.recentDevicesModal {
  min-height: 94vh;
  max-height: 94vh;
  overflow: auto;
}

.liveLectureReProcessButton {
  opacity: 0.4 !important;
}

.testimonial-record {
  color: #4183c4;
}

.testimonial-record:hover {
  color: #1e70bf;
  cursor: pointer;
}

.addques-newoption {
  margin-left: 5px !important;
  margin-top: 10px;
  color: #3abaa8;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 17%;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  background: #fff;
  border: 1px #fff;
}

.clone-job-newlocation {
  margin-left: 5px !important;
  margin-top: 10px;
  color: #3ABAA8;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  /* width: 17%; */
  height: 26px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  background: #FFF;
  border: 1px #FFF;
}

.labs-create-new-form-date_input {
  width: 70%;
  height: 48px;
  left: 406px;
  top: 216px;
  margin-left: 10px;
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.25);
  border-radius: 8px;
  font-size: 14px;
  padding: 5px;
}

.labs-create-new-form-date_input-2 {
  width: 98%;
  height: 48px;
  left: 406px;
  top: 216px;
  margin-left: 0px;
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.25);
  border-radius: 8px;
  font-size: 14px;
  padding: 5px;
}

.job-toggle {
  color: #fff;
  background-color: #58944c;
  border-radius: 10px;
  width: 50%;
  display: block;
  /* padding: 6px; */
}

.job-toggle-unchecked {
  color: #055646;
  width: 50%;
  display: block;
  /* padding: 16px; */
}

.job-tog-btn1 {
  border: none;
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  /* color:#055646; */
  /* margin-top: 10px; */
}

.jobs-grantaccessmodal {
  width: 40% !important;
}

.jobs-revokeaccessmodal {
  width: 30% !important;
}

.jobs-viewresumemodal {
  width: 40% !important;
}

.res-dl-btn {
  color: #055646 !important;
  text-align: right !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  /* 160% */
}

.job-dt-inp {
  margin-left: 10px !important;
  transform: scale(1.5) !important;
}

.job-apply-reminder {
  color: #3c4852;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.apply-reminder-txt {
  margin-left: 16px;
  margin-right: 18px;
}

.aply-success-txt {
  color: #3c4852;
  /* font-family: "Source Sans Pro"; */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.apply-success-btn {
  border-radius: 4px;
  background: #014644;
  color: #fff;
  /* font-family: "Source Sans Pro"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.apply-sucess-modal {
  max-width: 300px;
}

.prof-dtl-txt {
  color: #3c4852;
  /* font-family: "Source Sans Pro"; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.viewres-txt {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.resume-prev .modal-header {
  width: 100%;
}

.resume-prev .modal-title {
  width: 100%;
}

.resume-preview-modal {
  width: 60% !important;
  min-height: 500px !important;
}

.resume-preview-modal .modal-content {
  height: 750px !important;
}

.jobs-show-filter-modal {
  width: 50% !important;
}

.jobs-show-final-list-modal {
  width: 960px !important;
  min-width: 960px !important;
}

.modal-filter-dt-chk {
  font-size: 16px !important;
  font-weight: 600 !important;
  min-width: 50px !important;
}

.modal-dt-td-filter {
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding: 12px 4px;
}

.modal-rollout-dt-td-ineligible {
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding: 0px;
  background: #fff2cc !important;
}

.modal-sticky-third-1 {
  position: sticky;
  z-index: 100;
  background: #f1f2f5;
  border-right: none !important;
}

.modal-sticky-first-1 {
  position: sticky;
  z-index: 100;
  background: #f1f2f5;
  border-right: none !important;
}

.modal-sticky-second-1 {
  position: sticky;
  z-index: 100;
  background: #f1f2f5;
  border-right: none !important;
}

.modal-sticky-fourth-1 {
  position: sticky;
  z-index: 100;
  background: #f1f2f5;
}

.modal-rollout-dt-td-eligible {
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding: 0px;
  background: #D9EAD3 !important;
}

.adques-selected-modal {
  border-radius: 8px;
  /* border: 1px solid rgba(33, 42, 57, 0.15); */
  background: #fff;
  margin-right: 15px;
  max-width: 950px;
}

.filter-selected-table-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #3c4852;
  /* margin-left: 10px; */
  /*margin-right: 10px; */
}

.filter-selected-final-learner {
  margin-bottom: 10px;
  /* margin-top: 16px; */
  margin-left: 0px !important;
  margin-right: 0px !important;
}

#batch-data-table .MuiTableCell-root {
  border-right: 1px solid rgba(224, 224, 224);
  /* width: 100%; */
}

.sticky-header-both-zero-1 {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  min-width: 50px;
}

.sticky-header-both-first-1 {
  position: sticky;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-second-1 {
  position: sticky;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-third-1 {
  position: sticky;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.modal-filter-dt-th {
  font-size: 16px !important;
  font-weight: 600 !important;
  min-width: 200px !important;
  text-align: center !important;
}

.modal-sticky-header {
  position: sticky;
  top: 0;
  z-index: 200;
  background: #e8ecf1;
}

/* 
.btch-dt-chk {
  font-size: 16px !important;
  font-weight: 600 !important;
  min-width: 50px !important;
} */

.readonly-select {
  pointer-events: none;
  background-color: #f5f5f5;
  color: #aaa;
}

.email-settings-modal {
  /* max-width: 800px !important; */
  font-family: 'Source Sans Pro', sans-serif !important;
}

.email-settings-modal .row {
  margin-left: 0px;
  margin-right: 0px;
}

.email-settings-modal .checkbox-label {
  color: #212A39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 16px;
}

.email-settings-modal .true-label {
  color: #212A39;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.email-settings-modal .true-label-control {
  border-radius: 10px;
  border: 1.5px solid rgba(60, 72, 82, 0.40);
}

.email-settings-modal .me-2 {
  width: 50px;
  margin-right: 24px;
}

.email-settings-modal .settings-header {
  color: #3C4852;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.email-settings-modal .save-confirm-txt {
  color: #A09FA0;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
  text-transform: uppercase;
  display: flex;
}

.email-settings-modal .success-confirm-txt {
  color: #3C4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.email-settings-modal .save-confirm-btn-ctnr {
  display: flex;
  justify-content: center;
}

.email-settings-modal .save-settings-btn {
  width: 166px;
  height: 40px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #035642;
  text-transform: none !important;
}

.email-settings-modal .save-settings-btn:hover {
  background-color: #035642 !important;
}

.email-settings-modal .cancel-settings-btn {
  width: 166px;
  height: 40px;
  flex-shrink: 0;
  color: #FFF;
  background-color: #6C757D;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none !important;
}

.email-settings-modal .cancel-settings-btn:hover {
  background-color: #6C757D !important;
}

.offer-label {
  color: #212529;
  font-feature-settings: 'ss01' on, 'ss03' on;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

.as-dl-welcome-sl {
  max-width: 500px;
  border: 0px solid #ced4da;
}

.coding-details-container {
  display: flex;
  padding: 14px 24px;
  height: 100vh;
}

.coding-details-left-container {
  width: 58%;
  display: flex;
  flex-direction: column;
  padding-right: 24px;
}

.latest-coding-submission {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.latest-coding-submission-header {
  color: #212a39;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.latest-coding-submission-data {
  padding: 8px;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.08);
}

.latest-coding-submission-data-lang {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 24px;
  width: 50%;
}

.latest-coding-submission-data-lang-heading {
  color: rgba(60, 72, 82, 0.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
}

.latest-coding-submission-data-lang-name {
  color: #212a39;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.latest-coding-submission-data-subm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

.latest-coding-submission-code {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(3, 86, 66, 0.16);
  background: rgba(3, 86, 66, 0.08);
  margin-top: 16px;
}

.latest-coding-submission-code-text {
  color: #035642;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.coding-history {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 16px;
  margin-top: 24px;
  overflow: auto;
}

.coding-history-header {
  color: #212a39;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

/* .coding-history-items {
  overflow: auto;
} */

.coding-history-item {
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.08);
  padding: 12px;
}

.coding-history-item-review-1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.coding-history-item-review-1-header {
  color: #3421a4;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
}

.coding-history-item-review-1-time {
  color: #212a39;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.coding-history-item-review-2 {
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.coding-history-item-submission-1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.coding-history-item-submission-1-header {
  color: #1c8068;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
}

.coding-history-item-submission-1-time {
  color: #212a39;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.coding-history-item-submission-2 {
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.coding-history-item-submission-3 {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(3, 86, 66, 0.16);
  background: rgba(3, 86, 66, 0.08);
}

.coding-history-item-submission-3-text {
  color: #035642;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.coding-details-right-container {
  width: 42%;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: fit-content;
  padding: 24px 16px;
}

.coding-assessment-header {
  color: #212a39;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
}

.coding-assessment-text-msg {
  color: #212a39;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.coding-assessment-total-marks {
  color: #3c4852;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.coding-assessment-min-max-marks {
  color: #3421a4;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.cdng-btn {
  border-radius: 10px;
  background: #035642;
  cursor: pointer;
  padding: 16px 8px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
}