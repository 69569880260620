.placement-portal {
  .labs-main {
    margin-left: 250px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .jobs-main {
    /* margin-left: 50px; */
    font-family: 'Source Sans Pro', sans-serif;
    background: rgba(5, 86, 70, 0.06);
  }

  .labs-sidebar {
    position: fixed;
    z-index: 2;
    top: 15vh;
  }

  .jobs-root {
    z-index: 3;
  }

  .jobs-addquesmodal {
    width: 50% !important;
  }

  .jobs-editquesmodal {
    width: 50% !important;
  }

  .upload-div {
    width: 98%;
    height: 56px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px dashed rgba(60, 72, 82, 0.40);
    background: rgba(9, 114, 82, 0.10);
  }

  .upload-text {
    color: var(--097252, #097252);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    text-align: center;
    padding-top: 15px !important;
    height: 56px;
  }

  .ctc-row {
    width: 100% !important;
  }

  .radiotext {
    // overflow: hidden;
    color: #212A39;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .radiotext-2 {
    overflow: hidden;
    color: #212A39;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    width: 63px;
    height: 16px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
  }

  // a[href*="www.froala.com"] {
  //   display: none !important;
  // }
  // a[href='https://www.froala.com/wysiwyg-editor?k=u'] {
  //   font-size: 0px !important;
  //   padding: 0px !important;
  //   height: 0px !important;
  // }
  div.fr-wrapper>div>a {
    /* display: none !important; */
    /* position: fixed; */
    /* z-index: -99999 !important; */
    background: #fff !important;
    font-size: 0px !important;
    // padding: 0px !important;
    height: 0.1px !important;
    pointer-events: none !important;
  }

  .apply-sucess-modal {
    max-width: 300px;
  }

  // #isPasted {
  //   display: none;
  // }


  .fr-second-toolbar {
    display: none !important;
  }

  .companyTopRow {
    margin-left: 24px;
  }

  .companyWebsite {
    color: #3C4852;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .previewTag {
    color: #3C4852;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 40px;
    margin-top: 1rem;
  }

  .previewTag-2 {
    color: #3C4852;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 40px;
    margin-top: 1rem;
  }

  .previewTagResponse {
    margin-top: 1rem;
    color: #3C4852;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;
    max-height: 25px;
  }

  .previewTagResponse-3 {
    margin-top: 1rem;
    color: #3C4852;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-left: 4px; */
    max-height: 25px;
  }

  .previewTagResponse2 {
    margin-top: 1rem;
    color: #3C4852;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4px;
  }

  .skillTagDisplay {
    border-radius: 51px;
    background: rgba(5, 86, 70, 0.15);
    flex-shrink: 0;
    max-height: 25px;
    width: 125px;
    text-align: center;
  }

  .resizeHTML {
    margin-left: 45px;
    margin-right: 15px;
  }

  .ck-editor__editable_inline {
    min-height: 300px;
  }

  .addques-newoption {
    margin-left: 5px !important;
    margin-top: 10px;
    color: #3ABAA8;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    width: 17%;
    height: 26px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    background: #FFF;
    border: 1px #FFF;
  }

  .addfilter-newrule {
    margin-left: 5px !important;
    margin-top: 10px;
    color: #3ABAA8;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    // width: 17%;
    height: 26px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    background: #FFF;
    border: 1px #FFF;
  }

  .jobs-sidebar {
    position: fixed;
    z-index: 200;
    top: 60px;
    // margin-left: 50px;
    // border-radius: 12px !important;
    border: 0.5px solid rgba(33, 42, 57, 0.15);
    background: #FFF;
    height: 100vh;
  }

  .learner-filter-add-btn-ctnr {
    border-radius: 8px;
    margin-right: 15px;
  }

  .learner-filter-all {
    border-radius: 8px;
    border: 1px solid rgba(33, 42, 57, 0.15);
    background: #FFF;
    margin-right: 15px;
    margin-bottom: 24px;
  }

  .learner-filter-final-learner {
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .adques-all {
    border-radius: 8px;
    border: 1px solid rgba(33, 42, 57, 0.15);
    background: #FFF;
    margin-right: 15px;
  }

  .adques-header {
    border-bottom: 0.75px solid rgba(33, 42, 57, 0.25);
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    /* margin: 5px; */
  }

  .rollout-addapplicant-header {
    border-bottom: 0.75px solid rgba(33, 42, 57, 0.25);
    margin-bottom: 10px;
    margin-top: 12px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    /* margin: 5px; */
  }

  .adques-footer {
    /* border-bottom: 1px solid grey; */
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    /* margin: 5px; */
  }

  .learner-filter-course-container {
    margin-bottom: 24px;
    margin-top: 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    /* margin: 5px; */
  }

  .learner-filter-batch-container {
    margin-bottom: 24px;
    margin-top: 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    /* margin: 5px; */
  }

  .footer-justify-content {
    display: flex;
    justify-content: flex-end;
  }

  .addcrit-content {
    margin: 15px;
  }

  .addcrit-details {
    min-height: 125px;
  }

  .addcrit-footer {
    margin-top: 50px;
  }

  .job-rollout-footer {
    margin-top: 10px;
  }

  .labs-sidebar-elem {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    width: 250px;
    padding: 10px;
    padding-top: 15px;
    text-align: start;
    text-decoration: none;
    cursor: pointer;
  }

  .labs-sidebar-active {
    border-left: solid 8px;
    background-color: #00000071;
  }

  .jobs-sidebar-elem {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    /* border-radius: 8px; */
    color: #3C4852;
    width: 250px;
    padding: 10px;
    padding-top: 15px;
    text-align: start;
    text-decoration: none;
    cursor: pointer;
  }

  .jobs-sidebar-elem-0 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 15px;
    /* border-radius: 6px; */
    /* border: 1px solid rgba(33, 42, 57, 0.15); */
    color: #3C4852;
    width: 250px;
    padding: 10px;
    padding-left: 30px;
    padding-top: 15px;
    text-align: start;
    text-decoration: none;
    cursor: pointer;
  }

  .jobs-sidebar-active {
    /* border-left: solid 8px; */
    color: #FFF;
    background-color: #055646;
  }

  .topbar {
    background-color: #fff;
    height: 8vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
  }

  .v_application_topbar {
    height: 60px;
    // fill: #fff;
    // margin-top: 10px;
    margin-left: 25px;
    // background-color: #fff;
  }

  .labs-content {
    margin: 3rem;
  }

  .jobs-header {
    margin-top: 1rem;
    margin-left: 325px;
    border-radius: 4px;
    background: rgba(33, 42, 57, 0.08);
    height: 40px;
    display: flex;
    align-items: center;
  }

  .minheader-text1 {
    color: #055646 !important;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: "Source Sans Pro", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
    margin-top: 58px;
    margin-right: 10px;
  }

  .previewques {
    margin-left: 15px;
  }

  .jobs-content {
    margin-top: 58px;
    margin-left: 325px;
    padding-bottom: 40px;
  }

  .labs-table-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
  }

  .labs-table-header-2 {
    // font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
  }

  .jobs-table-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3c4852;
    margin-left: 10px;
    /*margin-right: 10px; */
  }

  .jobs-table-header-2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #3c4852;
    margin-left: 10px;
    /*margin-right: 10px; */
  }

  .jobs-table-header-22 {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #3c4852;
    padding-top: 16px;
  }

  .jobs-table-header-3 {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #3c4852;
    // margin-left: 10px;
    /*margin-right: 10px; */
  }

  .learner-filter-course-header {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    color: #212A39;
    // font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    /*margin-right: 10px; */
  }

  .topbar-heading {
    width: 100%;
    overflow: hidden;
    color: #3C4852;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    justify-items: center;
  }

  .labs-table-btn-1 {
    background: #035642 !important;
    width: 100% !important;
    height: 50px !important;
    border-radius: 8px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
  }

  .labs-table-btn-2 {
    width: 100%;
    height: 50px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background-color: #fff;
  }

  .job-clone-btn-2 {
    width: 100%;
    height: 50px;
    // border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background: rgba(60, 72, 82, 0.25); 
  }

  .labs-table-btn-3 {
    width: 100%;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background-color: #fff;
  }

  .bulk-dl-btn {
    width: 100%;
    height: 40px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background-color: #fff;
  }

  .labs-table-btn-4 {
    width: 100%;
    height: 40px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #FFF;
    background: #DC3545;
    justify-content: right;
  }

  .labs-table-btn-35 {
    max-width: 100px;
    height: 35px;
    border: 1px solid #035642;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #035642;
    background-color: #fff;
    margin-left: 10px;
  }

  .jobs-table-btn-5 {
    background: #035642 !important;
    width: 80% !important;
    height: 50px !important;
    border-radius: 8px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    margin-bottom: 5px;
  }

  .learner-add-aplicant-btn {
    background: #035642 !important;
    width: 80% !important;
    height: 40px !important;
    border-radius: 8px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    max-width: 134px;
  }

  .jobs-filter-add-container{
    min-width: 60%;
  }

  .jobs-table-btn-16 {
    background: #055646!important;
    width: 80% !important;
    text-align: center !important;
    color: #FFF !important;
    // font-family: "Source Sans Pro";
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important; 
    margin-bottom: 5px;
    border-radius: 8px !important;
    height: 50px !important;
  }

  .jobs-table-btn-6 {
    background: #FFF !important;
    color: #055646 !important;
    width: 80% !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    border-radius: 8px !important;
    border-color: #055646 !important;
    height: 50px !important;
  }

  .rolloutfilter-table-btn {
    background: #FFF !important;
    color: #055646 !important;
    width: 80% !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    border-radius: 8px !important;
    border-color: #055646 !important;
    height: 40px !important;
    max-width: 160px !important;
  }

  .jobs-table-btn-7 {
    background: #3ABAA8 !important;
    // width: 80% !important;
    height: 50px !important;
    border-radius: 8px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    margin-bottom: 5px;
  }

  .jobs-table-btn-8 {
    background: #FFF !important;
    // width: 80% !important;
    // height: 26px !important;
    border-radius: 4px !important;
    border: 1px solid #DC3545 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #DC3545 !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    padding: 4px !important;
  }

  .jobs-table-btn-88 {
    background: #DC3545 !important;
    width: 80% !important;
    height: 40px !important;
    border-radius: 4px !important;
    // border: 1px solid #DC3545 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #FFF !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    padding: 0px !important;
    max-width: 134px;
  }

  .jobs-extend-btn-3 {
    width: 100%;
    height: 35px;
    // border: 1px solid #035642;
    background: #3ABAA8 !important;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff !important;
    border: none !important;
    padding: 4px !important;
    cursor: pointer !important;
  }

  .jobs-table-btn-9 {
    background: #3ABAA8 !important;
    // width: 80% !important;
    // height: 26px !important;
    border-radius: 4px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    padding: 4px !important;
  }

  .jobs-table-btn-10 {
    background: #035642 !important;
    width: 80% !important;
    height: 26px !important;
    border-radius: 4px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    padding: 0px !important;
  }

  .jobs-table-btn-11 {
    background: #3ABAA8 !important;
    width: 100% !important;
    min-height: 45px !important;
    border-radius: 10px !important;
    border: none !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 18px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    margin-bottom: 5px;
    padding: 0px !important;
    // display: flex;
    outline: none;
    // margin-top: 10px;
  }

  .resume-prev h5 {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .resume-prev .modal-header {
    width: 100%;
  }

  p[data-f-id="pbf"] {
    opacity: 0;
    display: none;
  }


  .job-fltr-modal {
    overflow: scroll;
    height: 400px;
  }

  .labs-edit-btn {
    height: auto;
    width: auto;
    padding: 6px;
    font-size: 12px;
    background-color: #198dd5;
    border-radius: 4px;
  }

  .labs-create-new-form {
    padding: 10px;
  }
  .jobs-create-new-form {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
  }


  .labs-create-new-form>div {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #212a39;
    padding-bottom: 8px;
  }

  .labs-create-new-form-text_input {
    width: 100%;
    height: 48px;
    left: 406px;
    top: 216px;
    background: #ffffff;
    border: 1px solid rgba(60, 72, 82, 0.25);
    border-radius: 8px;
    font-size: 14px;
    padding: 5px;
  }

  .labs-create-new-form-date_input {
    width: 80%;
    height: 52px;
    left: 406px;
    top: 216px;
    margin-left: 10px;
    background: #ffffff;
    border: 1px solid rgba(60, 72, 82, 0.25);
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
  }

  .labs-disabled {
    background: rgba(60, 72, 82, 0.08);
  }

  .labs-create-new-header {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #3c4852;
  }

  .labs-add-student-card-main {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000005b;
  }

  .labs-add-student-card-content {
    width: 30%;
    background-color: #fff;

    padding: 1rem;
    border-radius: 8px;
  }

  .labs-add-student-card-header {
    display: flex;
    justify-content: space-between;
  }

  .labs-add-student-card-header>span {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #212a39;
  }

  .jobs-border {
    border-bottom: solid;
  }

  .labs-table-req-body>td {
    padding: 10px;
    border-bottom: solid 1px;
  }

  .labs-Active,
  .labs-Inactive {
    background-color: rgb(43, 173, 17);
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }

  .labs-Inactive {
    background-color: rgb(207, 16, 16);
  }

  .labs-note {
    font-size: 12px;
    color: #000000a1;
  }

  .lab-capital {
    text-transform: capitalize;
  }

  .as-dl-header {
    margin-left: 250px;
    height: 80px;
    // width: 100% - 250px;
    background-color: #055646;
    color: var(--white-color);
    font-size: 24px;
    line-height: 80px;
    font-weight: 800;
    text-align: center;
  }

  .rollout-toggle-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;
    padding-right: 15px;
  }

  .toggle-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;
  }

  .toggle-container2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    // padding-bottom: 24px;
  }

  .as-dl-sidebar {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    background-color: #055646;
    color: var(--white-color);
    overflow-x: hidden;
    z-index: 1;
  }

  .as-dl-sidebar-lg {
    height: 80px;
  }

  .as-dl-logo {
    height: 32px;
    margin-top: 30px;
    width: auto;
    margin-left: 40px;
  }

  .btch-search {
    display: flex !important;
    justify-content: flex-end;
    width: fit-content;
    margin: unset;
    z-index: 999;
  }

  .btch-search2 {
    display: flex !important;
    justify-content: flex-start;
    width: fit-content;
    margin: unset;
  }

  .btch-dt-chk {
    font-size: 16px !important;
    font-weight: 600 !important;
    min-width: 50px !important;
    // text-align: center !important;
  }

  .resume-div {
    width: 98%;
    height: 56px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid rgba(60, 72, 82, 0.4);
    background: rgba(235, 238, 237, 0.1);
  }

  .resume-heading {
    color: #3c4852;
    // font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
  }

  .as-dl-logo-job-3 {
    height: 32px;
    margin-top: 12px;
    width: auto;
    /* margin-left: 20px; */
  }

  .btch-dt-chk-3 {
    font-size: 16px !important;
    font-weight: 600 !important;
    min-width: 50px !important;
    // text-align: center !important;
  }

  .btch-dt-chk-2 {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  .btch-dt-all {
    margin-left: 0;
    transform: scale(2) !important;
  }

  .btch-dt-all-unsub {
    margin-left: 0;
    transform: scale(2) !important;
  }

  .job-dt-inp {
    margin-left: 10px !important;
    transform: scale(1.5) !important;
  }

  .job-samefilter-inp {
    margin-left: 10px !important;
    transform: scale(1.5) !important;
  }

  .form-check-input {
    margin-left: 10px !important;
  }

  .resume-link {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
    /* Smooth transition for color change */
    color: #0ba5ec;
  }

  .resume-link:hover {
    color: #055646;
    /* Change color on hover */
  }

  .job-dt-tog-btn1 {
    border: none;
    display: flex;
    width: 100%;
    max-height: 50px;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    /* color:#055646; */
    margin-top: 10px;
  }

  .job-dt-tog-btn2 {
    border: none;
    display: flex;
    width: 33%;
    max-height: 50px;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    /* color:#055646; */
    margin-top: 10px;
  }

  .job-dt-tog-btn3 {
    border: none;
    display: flex;
    width: 33.33%;
    // max-height: 50px;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color:#055646; 
    // margin-top: 10px;
    justify-content: center;
  }

  .job-screen-dt-tog-btn3 {
    border: none;
    display: flex;
    width: 50%;
    // max-height: 50px;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color:#055646; 
    // margin-top: 10px;
    justify-content: center;
  }

  .app-round-dsply {
    margin-top: 10px;
    border-radius: 8px;
    background: #f0f0f0;
    // max-height: 50px;
    width: 100%;
  }

  .app-round-dsply-2 {
    margin-top: 10px;
    border-radius: 8px;
    background: #f0f0f0;
    // max-height: 50px;
    width: 100%;
  }

  

  .btch-toggle {
    color: #FFF;
    background-color: #055646;
    border-radius: 10px;
    width: 50%;
    display: block;
    padding: 10px;
  }

  .btch-toggle-unchecked {
    color: #055646;
    width: 50%;
    display: block;
    padding: 10px;
  }

  .btch-toggle-unchecked-2 {
    color: #055646;
    width: 100%;
    display: block;
    padding: 10px;
  }

  .btch-toggle-2 {
    color: #FFF;
    background-color: #055646;
    border-radius: 10px;
    width: 100%;
    display: block;
    padding: 10px;
  }

  .btch-toggle-unchecked-3 {
    color: #055646;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .btch-toggle-3 {
    color: #FFF;
    background-color: #055646;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .btch-dt-inp {
    margin-left: 15px;
    /* transform: scale(2) !important; */
  }

  .job-dt-chk {
    margin-left: 15px;
    /* transform: scale(2) !important; */
  }

  .btch-dt-inp-ineligible {
    margin-left: 15px;
    /* transform: scale(2) !important; */
  }

  .btch-dt-inp-unsub {
    margin-left: 0;
    transform: scale(2) !important;
  }

  .btch-dt-th-2 {
    font-size: 16px !important;
    font-weight: 600 !important;
    // max-width: 25px !important;
    text-align: center !important;
  }

  .btch-dt-th-3 {
    font-size: 16px !important;
    font-weight: 600 !important;
    max-width: 15px !important;
    text-align: center !important;
  }

  .btch-dt-th {
    font-size: 16px !important;
    font-weight: 600 !important;
    min-width: 200px !important;
    text-align: center !important;
  }

  .btch-dt-td {
    font-size: 13px !important;
    font-weight: 600 !important;
    // min-width: 120px !important;
    text-align: center !important;
    padding: 0px;
  }

  .full-height {
    height: 100% !important;
  }

  .job-rollout-dt-td-eligible {
    font-size: 13px !important;
    font-weight: 600 !important;
    // min-width: 120px !important;
    text-align: center !important;
    padding: 0px;
    background: #D9EAD3!important;
  }

  .job-rollout-dt-td-ineligible {
    font-size: 13px !important;
    font-weight: 600 !important;
    // min-width: 120px !important;
    text-align: center !important;
    padding: 0px;
    background: #FFF2CC !important;
  }
  .btch-dt-td-spl {
    font-size: 13px !important;
    font-weight: 600 !important;
    // min-width: 120px !important;
    text-align: center !important;
    padding: 0px 4px;
  }

  .btch-dt-td-app {
    font-size: 13px !important;
    font-weight: 600 !important;
    // min-width: 120px !important;
    text-align: center !important;
    padding: 12px 4px;
  }

  .btch-dt-td-4 {
    font-size: 13px !important;
    font-weight: 600 !important;
    min-width: 350px !important;
    text-align: center !important;
    padding: 5px !important;
  }

  .btch-dt-td-3 {
    font-size: 13px !important;
    font-weight: 600 !important;
    min-width: 125px !important;
    text-align: center !important;
  }

  .btch-dt-td-2 {
    font-size: 13px !important;
    font-weight: 600 !important;
    min-width: 525px !important;
    text-align: center !important;
  }

  .custom-unsub-text {
    display: inline-block;
    background-color: #f1b605;
    border: 1px solid #ffc107;
    color: #fff;
    padding: 8px 16px;
    text-align: center;
  }

  .custom-sub-text {
    display: inline-block;
    background-color: #35a44f;
    border: 1px solid #35a44f;
    color: #fff;
    padding: 8px 16px;
    text-align: center;
  }

  .btch-pending {
    display: 'flex';
    /* color:#FFF; */
    /* background-color: #055646; */
    border-radius: 1px;
    width: 30%;
    display: block;
    padding: 1px;
    justify-content: space-between !important;
  }

  .job-toggle {
    color: #FFF;
    background-color: #58944C;
    border-radius: 10px;
    width: 50%;
    display: block;
    /* padding: 6px; */
  }

  .job-toggle-unchecked {
    color: #055646;
    width: 50%;
    display: block;
    /* padding: 16px; */
  }

  .job-tog-btn1 {
    border: none;
    display: flex;
    width: 100%;
    height: 30px;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    /* color:#055646; */
    /* margin-top: 10px; */
  }

  #batch-data-table .MuiTableCell-root {
    border-right: 1px solid rgba(224, 224, 224);
    /* width: 100%; */
  }

  .sticky-first {
    position: sticky;
    left: 0;
    z-index: 100;
    background: #f1f2f5;
    border-right: none !important;
  }

  .sticky-second {
    position: sticky;
    left: 50px;
    z-index: 100;
    background: #f1f2f5;
    border-right: none !important;
  }

  .sticky-third {
    position: sticky;
    left: 225px;
    z-index: 100;
    background: #f1f2f5;
    border-right: none !important;
  }

  .sticky-fourth {
    position: sticky;
    left: 400px;
    z-index: 100;
    background: #f1f2f5;
  }

  .sticky-first-1 {
    position: sticky;
    // left: 0;
    z-index: 100;
    background: #f1f2f5;
    border-right: none !important;
  }

  .sticky-second-1 {
    position: sticky;
    // left: 50px;
    z-index: 100;
    background: #f1f2f5;
    border-right: none !important;
  }

  .sticky-third-1 {
    position: sticky;
    // left: 225px;
    z-index: 100;
    background: #f1f2f5;
    border-right: none !important;
  }

  .sticky-fourth-1 {
    position: sticky;
    // left: 400px;
    z-index: 100;
    background: #f1f2f5;
  }

  .sticky-header-both-first {
    position: sticky;
    left: 50px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-first-1 {
    position: sticky;
    // left: 50px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-second {
    position: sticky;
    left: 225px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-second-1 {
    position: sticky;
    // left: 225px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-third {
    position: sticky;
    left: 400px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-third-1 {
    position: sticky;
    // left: 400px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-fourth {
    position: sticky;
    left: 400px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-zero {
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
  }

  .sticky-header-both-zero-1 {
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    min-width: 50px;
  }

  .sticky-header-both-first-2 {
    position: sticky;
    left: 10px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-second-2 {
    position: sticky;
    left: 225px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-third-2 {
    position: sticky;
    left: 400px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header-both-fourth-2 {
    position: sticky;
    left: 400px;
    top: 0;
    z-index: 300;
    background: #e8ecf1;
    border-right: none !important;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 200;
    background: #e8ecf1;
  }

  .batchDataExtractionNew .MuiAutocomplete-root {
    background: #055646;
    margin: 60px auto;
    min-width: 95%;
  }

  .batchDataExtractionNew .MuiInputLabel-root,
  .batchDataExtractionNew .MuiSvgIcon-root,
  .batchDataExtractionNew .MuiInputBase-root {
    color: white !important;
  }

  .batchDataExtractionNew .MuiInputLabel-root.Mui-focused,
  .batchDataExtractionNew .MuiInputBase-root.Mui-focused,
  .batchDataExtractionNew .MuiOutlinedInput-notchedOutline.Mui-focused {
    color: white !important;
  }

  .batchDataExtractionNew .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
  }

  .batchDataExtractionNew .MuiOutlinedInput-notchedOutline.Mui-focused {
    border-color: white !important;
  }

  .ui.green.button {
    float: right !important;
  }

  .batchDataExtractionNew .MuiInputLabel-root.Mui-disabled,
  .batchDataExtractionNew .Mui-disabled .MuiSvgIcon-root,
  .batchDataExtractionNew .MuiInputBase-root.Mui-disabled {
    color: rgb(165, 165, 165) !important;
  }

  .batchDataExtractionNew .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgb(165, 165, 165) !important;
  }

  .btch-dt-foot-main {
    display: flex;
    margin-top: 20px;
    margin-left: 250px;
    padding: 10px;
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    z-index: 300;
    background-color: #3C4852;
    position: fixed;
  }

  .btch-dt-foot-cnt {
    color: #FFF;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 30px;
    margin-left: 100px;
  }

  .btch-dt-foot-btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 350px;
    height: 60px;
    /* flex-direction: column; */
    justify-content: center;
    flex-shrink: 0;
    margin-right: 330px;
  }

  .btch-dt-foot-btn-3 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    // width: 350px;
    height: 60px;
    /* flex-direction: column; */
    justify-content: center;
    flex-shrink: 0;
    margin-right: 330px;
  }

  .btch-dt-foot-btn1 {
    width: 200px;
    /* height: 100px; */
    border-radius: 10px;
    outline: none;
    // font-size: 18px;
    font-weight: 600;
    color: #055646;
    margin-top: 10px;
  }

  .btch-dt-foot-btn3 {
    // width: 300px !important;
    border: 1px solid #FFF; 
    /* height: 100px; */
    border-radius: 10px !important;
    outline: none !important;
    background: #3C4852 !important; 
    font-weight: 600 !important;
    color: #FFF !important;
    margin-top: 10px !important;
  }

  .profile-job-selected {
    color: #055646;
    // font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    border-bottom: 5px solid #055646;
  }

  .profile-job {
    color: #3c4852;
    // font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
  }

  .profile-img {
    border-radius: 50%;
    width: 100%;
    // width: 28px;
    margin-top: -5px;
    object-fit: contain;
  }

  .prof-dtl-val {
    color: rgba(60, 72, 82, 0.50);
    // font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .job-display-profile {
    border-bottom: 1px solid rgba(33, 42, 57, 0.15);
  }

  .profile-setting-container-8 {
    width: 100%;
    max-width: 880px;
    margin: 0 10px;
    border-radius: 4px;
    padding-bottom: 0px;
    color: #3c4852;
    // font-family: "Source Sans Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-top: 20px;
  }

  .resume-icon-2 {
    min-height: 40%;
    min-width: 40%;
  }

  .resume-txt {
    display: flex;
    margin-left: 8px;
    flex-direction: column;
  }
  
  .resume-wrap {
    display: flex;
    justify-content: space-between;
    width: 88%;
  }

  .full-space-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 60%;
    /* overflow: hidden; */
  }

  .job-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    flex-direction: column;
  }

  .job-not-found-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column;
  }

  .modal-header {
    color: #212A39;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-weight: 800;
  }

  .modal-title {
    color: #212A39;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .revenuemaincontainer {
    margin-top: 15px;
    // margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
  }
  
  .revenuecontainer h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #3c4852;
    letter-spacing: -0.304px;
  }
  
  .revenuecontainer h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.76px;
    color: #097252;
  }
  
  .revenuecontainer {
    width: 16%;
    height: 122px;
    border-radius: 16px;
    border: 1px solid rgba(3, 86, 66, 0.5);
    background: #fff;
    // box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25);
    padding: 16px 24px;
  }

  .revenuecontainer-2 h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #3c4852;
    letter-spacing: -0.304px;
  }
  
  .revenuecontainer-2 h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.76px;
    color: #097252;
  }
  
  .revenuecontainer-2 {
    // width: 16%;
    // height: 122px;
    border-radius: 16px;
    border: 1px solid rgba(3, 86, 66, 0.5);
    background: #fff;
    // box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25);
    padding: 16px 8px;
  }

  .revenuemaincontainer-2 {
    margin-bottom: 15px;
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }

  .group-db-content-wrapper-2 {
    position: relative;
    left: 0;
    margin-bottom: 24px;
    min-height: 40px;
    overflow-x: hidden;
    height: 40%;
    /* padding-top: 40px; */
  }

  .grant-margin-top {
    margin-top: 38px;
    margin-bottom: 24px;
  }

  .add-border-bottom {
    border-bottom: 1px solid grey;
  }

  .img-container-2 {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .profile-preview-heading {
    color: #3c4852;
    // font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dashboard-content-2 {
    overflow: hidden;
    /* max-width:880px; */
    /* top right bottom left */
    padding: 0px 16px 24px 16px;
    /* overflow: hidden; */
    /* padding-bottom: 760px; */
  }

  .profile-setting-container-row {
    border-radius: 8px;
    border: 1px solid rgba(60, 72, 82, 0.25);
    background: #FFF; 
  }

  .profile-setting-container-7 {
    width: 100%;
    background: #fff;
    min-height: 150px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    /* margin-bottom:10px; */
    padding-bottom: 20px;
    font-family: "Source Sans Pro", sans-serif;
  }

  .profile-bar {
    margin-left: 10px;
    padding-top: 36px;
  }
  
  .profile-bar-heading {
    color: #3c4852;
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .profile-bar-detail {
    color: #3c4852;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px;
  }
  
  .profile-bar-progress {
    border-radius: 32px;
    background: rgba(60, 72, 82, 0.08);
    height: 25%;
    padding: 0px !important;
  }
  
  .profile-bar-completed {
    border-radius: 32px;
    background: linear-gradient(270deg, #469cec 0%, #3abaa8 100%);
    height: 100%;
    min-height: 5px;
  }

  .profile-bar-total {
    flex-direction: row;
    display: flex;
  }

  .profile-updt-dtl {
    display: flex;
    justify-content: space-between;
  }

  // .apply-sucess-modal {
  //   max-width: 300px;
  // }

  .prof-dtl-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aply-success-txt {
    color: #3c4852;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .test-heading-left {
    left: 0px;
    width: 100px !important;
    z-index: 999;
  }

  .test-heading-left-2 {
    left: 48px;
    width: 100px !important;
    z-index: 999;
  }

  .test-heading-left-3 {
    left: 100px;
    width: 100px !important;
    z-index: 999;
  }

  .test-heading-left-4 {
    left: 280px;
    width: 100px !important;
    z-index: 999;
  }


  .test-left {
    left: 0px;
    width: 100px !important;
    z-index: 990;
  }

  .test-left-2 {
    left: 48px;
    width: 100px !important;
    z-index: 990;
  }

  .test-left-3 {
    left: 100px;
    width: 100px !important;
    z-index: 990;
  }

  .test-left-4 {
    left: 280px;
    width: 100px !important;
    z-index: 990;
  }
  .padding-eight {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .final-select-heading-left {
    left: 0px;
    width: 100px !important;
    z-index: 999;
  }

  .final-select-heading-left-2 {
    left: 48px;
    width: 100px !important;
    z-index: 999;
  }

  .final-select-heading-left-3 {
    left: 240px;
    width: 100px !important;
    z-index: 999;
  }


  .final-select-left {
    left: 0px;
    width: 100px !important;
    z-index: 990;
  }

  .final-select-left-2 {
    left: 48px;
    width: 100px !important;
    z-index: 990;
  }

  .final-select-left-3 {
    left: 240px;
    width: 100px !important;
    z-index: 990;
  }

  .profile-prev-job-exp-heading {
    color: #3c4852;
    // font-family: "Source Sans Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
  }

}